




















































import { Component, Vue, Prop } from "vue-property-decorator";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
@Component({ components: {} })
export default class PacienteFichaMasOpciones extends Vue {
  @Prop() id_paciente!: number;
  public dialog = false;
  public counterclickOp: any[] = [];
  created() {
    if (this.id_paciente === undefined) {
      this.$router.push({
        name: RouterNames.pacienteslista,
      });
    }
  }

  public get allbuttons() {
    return [
      {
        id: 1,
        titulo: "Preguntas",
        descripcion: "Historial de preguntas al paciente",
        icono: "fad fa-map-marker-question",
        visible: true,
        routername: RouterNames.histpreguntaspaciente,
      },
      {
        id: 2,
        titulo: "Mediciones",
        descripcion: "Mediciones de uso avanzado",
        icono: "fad fa-ruler-vertical",
        visible: true,
        routername: RouterNames.mediciones_personalizadas_lista,
      },
      {
        id: 3,
        titulo: "Objetivos",
        descripcion: "Objetivos a alcanzar",
        icono: "fad fa-bullseye-arrow",
        visible: true,
        routername: RouterNames.objetivos_lista,
      },
      {
        id: 4,
        titulo: "Anotaciones",
        descripcion: "BD de anotaciones de las consultas",
        icono: "fad fa-file-alt",
        visible_paci_no_existe: false,
        routername: RouterNames.anotaciones_consultas,
      },
      {
        id: 5,
        titulo: "Gustos",
        descripcion: "Gustos del paciente",
        icono: "far fa-stomach",
        visible_paci_no_existe: false,
        routername: RouterNames.gustos_paciente,
      },
      {
        id: 6,
        titulo: "Marketing",
        descripcion: "Configura los envios de correo para el paciente",
        icono: "far fa-mail-bulk",
        visible_paci_no_existe: false,
        routername: RouterNames.marketing_paciente,
      },
      {
        id: 7,
        titulo: "Notificaciones móvil",
        descripcion: "Configura las notificaciones para el paciente",
        icono: "far fa-tablet-android",
        visible_paci_no_existe: false,
        routername: RouterNames.notificaciones_movil,
      },
      {
        id: 8,
        titulo: "Cirujias",
        descripcion: "Historial clínico de cirujias",
        icono: "fad fa-file-medical-alt",
        visible: true,
        routername: RouterNames.paciente_historial_cirujias,
      },
      {
        id: 9,
        titulo: "Medicamentos",
        descripcion: "Historial clínico de medicamentos",
        icono: "fad fa-capsules",
        visible: true,
        routername: RouterNames.paciente_historial_medicamentos,
      },
      {
        id: 10,
        titulo: "Diagnósticos",
        descripcion: "Historial clínico de diagnósticos",
        icono: "fad fa-notes-medical",
        visible: true,
        routername: RouterNames.paciente_historial_diagnosticos,
      },
      {
        id: 11,
        titulo: "Vacunas",
        descripcion: "Historial clínico de vacunas",
        icono: "fad fa-syringe",
        visible: true,
        routername: RouterNames.paciente_historial_vacunas,
      },
    ];
  }
  public get botones() {
    let botones_visibles = this.allbuttons.filter((x) => (x.visible = true));
    return botones_visibles;
  }
  public showPopup(): boolean {
    const result = this.counterclickOp.filter(
      (item) => this.$route.name === item.routername
    );

    return result.length >= 3;
  }

  public clickbutton(item: any) {
    if (this.$route.name === item.routername) {
      this.counterclickOp.push(item);
      this.dialog = this.showPopup();
      return;
    }
    switch (item.id) {
      case 1:
        this.$router.push({
          name: RouterNames.histpreguntaspaciente,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 2:
        this.$router.push({
          name: RouterNames.mediciones_personalizadas_lista,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 3:
        this.$router.push({
          name: RouterNames.objetivos_lista,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 4:
        this.$router.push({
          name: RouterNames.anotaciones_consultas,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 5:
        this.$router.push({
          name: RouterNames.gustos_paciente,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 6:
        this.$router.push({
          name: RouterNames.marketing_paciente,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 7:
        this.$router.push({
          name: RouterNames.notificaciones_movil,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 8:
        this.$router.push({
          name: RouterNames.paciente_historial_cirujias,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 9:
        this.$router.push({
          name: RouterNames.paciente_historial_medicamentos,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 10:
        this.$router.push({
          name: RouterNames.paciente_historial_diagnosticos,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
      case 11:
        this.$router.push({
          name: RouterNames.paciente_historial_vacunas,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString()),
          },
        });
        break;
    }
  }
}
